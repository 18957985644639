.item-list-page {
  padding-top: 70px;

  .products-header {
    background: $white2;
    padding: 20px 0;

    h2 {
      font-family: $font1;
      padding: 15px;
      font-size: 2.5rem;
      @media screen and (max-width: 880px) {
        font-size: 1.5rem;
      }
    }

    .good-choice-container {
      display: flex;
      justify-content: space-evenly;
      align-items: start;
      flex-wrap: wrap;

      .good-choice {
        padding-bottom: 30px;
        p {
          width: 350px;
          font-size: 1.2rem;
          letter-spacing: 1px;
          text-align: center;
          @media screen and (max-width: 880px) {
            font-size: 0.9rem;
          }
        }
        img {
          width: 70px;
          margin: 30px 5px 20px 5px;
          @media screen and (max-width: 880px) {
            font-size: 1.7rem;
            margin: 5px 0 10px 0;
          }
        }

        .additional-text {
          font-size: 0.9rem;
        }
        .read-more-btn {
          cursor: pointer;
          p {
            color: $blue1;
            margin-bottom: 5px;
          }
        }
        i {
          color: $blue2;
          font-size: 1.7rem;
          transition: all 0.3s ease-in-out;
          &:hover {
            transform: translateY(5px);
          }
        }
        @media screen and (max-width: 880px) {
          padding-bottom: 10px;
        }
      }
    }
  }

  .item-list-nav {
    position: sticky;
    top: -1px;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white1;
    height: 90px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);

    @media screen and (max-width: 880px) {
      flex-direction: column;
      gap: 20px;
      height: 100px;
    }
      
      .category-select {
        height: 40px;
        padding: 5px 5px 5px 15px;
        width: 100%;
        border: none;
        cursor: pointer;
        font-size: 1rem;
        border-bottom: 1px solid $black1;
        background-color: white;

        @media screen and (max-width: 880px) {
          width: 298px;
          height: 30px;
          margin-left: 0;
        }
      }

      .custom-select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-image: url('../../../assets/img/arrow-down.png');
        background-repeat: no-repeat;
        background-position: right center;
        padding-right: 30px;
        background-size: 30px 30px
    }

    ul {
      list-style: none;
      display: flex;
      gap: 20px;

      @media screen and (max-width: 880px) {
        gap: 10px;
        width: 298px;
      }

      li {
        display: flex;
        input {
          display: none;
          margin-right: 5px;
        }

        label {
          padding: 10px;
          min-width: 100px;
          height: 40px;
          margin: 10px 0 10px 10px;
          font-size: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transition: all 0.3s ease;

          @media screen and (max-width: 880px) {
            height: 30px;
            margin: 10px 0;
            width: 67px;
            font-size: 0.8rem;
          }

          &:hover {
            background-color: $blue3;
          }

          &.selected {
            background-color: $blue2;
          }
        }
      }
    }
    .search-input {
      padding: 5px;
      height: 40px;
      margin-left: 100px;
      border-bottom: 1px solid $black1;
      cursor: pointer;
      display: flex;
      align-items: center;

      @media screen and (max-width: 880px) {
        width: 298px;
        justify-content: space-between;
        height: 30px;
        margin-left: 0;
      }

      input[type="text"] {
        background: none;
        outline: none;
        border: none;
        margin-left: 10px;
        width: 100%;
      }
      input[type="text"]::placeholder {
        color: $black1;

        @media screen and (max-width: 880px) {
          font-size: 1rem;
        }
      }

      i {
        color: $black1;
        font-size: 1.3rem;
        margin-right: 5px;
        @media screen and (max-width: 880px) {
          font-size: 1.1rem;
        }
      }
    }
  }

  .item-list-container {
    display: flex;
    justify-content: center;
    background: $white2;
    flex-wrap: wrap;

    .item-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;

      .empty-msg {
        min-height: 50vh;
        font-size: 1.3rem;
        display: flex;
        align-items: center;
      }
    }
  }
  .bg-img {
    background: url(../../img/img-5.jpg) center/cover;
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -100;
  }

  .fixed-cart {
    top: 110px;
    right: 10px;
  }
}
