.forgot-password-page {
  background: url(../../img/img-5.jpg) center/cover;
  height: 100vh;
  padding-top: 150px;

  .form-container {
    position: relative;
    background: $white1;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    z-index: 100;
    width: 80%;
    margin: 0 10% 50px 10%;
    padding: 10px;
    display: flex;
    font-weight: 700;
    flex-direction: column;
    align-items: center;

    h2 {
      margin: 30px;
      font-family: $font1;
      font-size: 1.9rem;
    }
    .input-container {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: flex-start;

      input {
        width: 60%;
        height: 30px;
        font-family: $font2;
        color: $black1;
        padding: 10px 0;
        margin: 10px;
        background: none;
        outline: none;
        border: none;
        border-bottom: 1px solid $black1;

        resize: none;
        &::placeholder {
          font-size: 0.8rem;
        }
      }
    }

    button {
      height: 25px;
      width: 60%;
      min-width: 140px;
      color: $black1;
      font-family: $font2;
      font-weight: 600;
      font-size: 0.8rem;
      text-transform: uppercase;
      letter-spacing: 3px;
      background-color: $blue2;
      border: none;
      margin: 35px 0;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:hover {
        transform: translateY(-3px);
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
      }
    }

    label {
      width: 120px;
      text-align: center;
    }
  }
}
