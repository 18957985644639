form {
  width: 100%;
  .payment-btn {
    padding: 10px;
    width: 95%;
    height: 40px;
    margin: 10px;
    background-color: $blue2;
    border: none;
    font-size: 1.2rem;
    letter-spacing: 1px;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    }
  }
}
