.item-container {
  position: relative;
  flex: 1 0 25%;
  min-width: 250px;
  height: auto;
  max-height: 500px;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 30px 0;
  padding-top: 10px;
  overflow: hidden;
  flex-wrap: wrap;

  img {
    width: 100%;
    height: 220px;
    filter: brightness(120%);
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    transform: scale(1.2);
    padding-left: 15px;
    object-fit: contain;
  }

  .title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-transform: uppercase;
    font-size: 0.9rem;
    margin-top: 20px;
    width: 100%;
    .weight {
      margin: 10px 0;
      font-size: 0.8rem;
    }
  }
  .btn-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .price-qty-container {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      align-content: center;
      width: 200px;

      .price {
        font-size: 1rem;
        font-weight: 600;
      }
      .qty-btn-container {
        display: flex;
        .qty-btn {
          display: flex;
          margin: 5px;
          width: 25px;
          height: 25px;
          transition: all 0.3s ease;

          &:hover {
            transform: scale(1.2);
          }
          i {
            margin: 0 5px;
            cursor: pointer;
            transform: translateY(20%);
          }
        }
        .quantity {
          font-size: 18px;
          font-weight: 700;
          margin: 0 5px;
          color: $black1;
          transform: translateY(20%);
        }
      }
    }
    .cart-btn {
      padding: 10px;
      width: 100%;
      height: 30px;
      margin: 10px;
      background-color: $blue2;
      border: none;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
      }

      i {
        margin-left: 10px;
      }
    }
    .unavailable {
      background-color: $red2;
      cursor: default;

      &:hover {
        transform: none;
        box-shadow: none;
      }
    }

    .cart-btn.added {
      background-color: rgb(0, 184, 0);
    }
  }
}
