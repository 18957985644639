.user-page {
  padding-top: 130px;

  .bg-img {
    background: url(../../img/img-5.jpg) center/cover;
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
  }

  .user-container {
    position: relative;
    background: $white1;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    z-index: 100;
    width: 80%;
    margin: 0 10% 50px 10%;
    padding: 10px;
    display: flex;
    font-weight: 700;
    flex-direction: column;
    align-items: center;

    h2,
    h3 {
      margin: 20px;
      font-family: $font1;
      font-size: 1.9rem;
    }

    .user-info {
      margin: 20px;
      padding: 20px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: $white1;
      border: solid 1px grey;
      width: 90%;

      .info {
        font-size: 1.2rem;
        padding: 10px;
        box-shadow: 5px;
        margin: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .password-container {
          display: flex;
          align-items: center;
          p {
            font-size: 2rem;
          }

          button {
            padding: 10px;
            width: 100%;
            margin: 10px 0 10px 10px;
            background-color: $blue2;
            border: none;
            font-size: 16px;
            cursor: pointer;
            transition: all 0.3s ease;

            &:hover {
              transform: translateY(-2px);
              box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
            }
          }
        }
      }
    }

    .contact-details {
      margin: 20px;
      padding: 20px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: $white1;
      width: 90%;
      border: solid 1px grey;

      .info {
        font-size: 1.2rem;
        padding: 10px;
        box-shadow: 5px;
        margin: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .editable-input {
          display: flex;
          align-items: center;

          .input {
            border: 0;
            border-bottom: 1px solid $black1;
            font-size: 1.3rem;
            padding: 7px 0;
            background: transparent;
          }
          p {
            font-size: 2rem;
          }

          button {
            padding: 10px;
            width: 100px;
            height: 30px;
            margin: 10px 0 10px 10px;
            background-color: $blue2;
            border: none;
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: all 0.3s ease;

            &:hover {
              transform: translateY(-2px);
              box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
            }
          }
        }
      }
    }
    .logout-btn {
      padding: 10px;
      height: 40px;
      width: 200px;
      margin: 10px 0 10px 10px;
      background-color: $blue2;
      border: none;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
      }
    }
  }
}
