.cartValidation-page {
  position: relative;
  background: $white1;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  z-index: 200;
  width: 80%;
  margin: 0 10% 50px 10%;
  display: flex;
  font-weight: 700;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;

  @media screen and (max-width: 880px) {
    width: 90%;
    margin: 0 5% 150px 5%;
  }

  @media screen and (max-width: 700px) {
    width: 95%;
    margin: 0 2.5% 200px 2.5%;
  }
  .header {
    h3 {
      font-family: $font1;
      font-size: 2.5rem;
      margin: 30px 0 -20px 0;

      @media screen and (max-width: 700px) {
        font-size: 2rem;
      }
    }
  }

  .empty-cart {
    padding: 100px 0 50px;
    font-size: 1.5rem;
    font-weight: 200;
    display: flex;
    flex-direction: column;

    button {
      padding: 10px;
      height: 40px;
      width: 200px;
      margin: 10px 0 20px 10px;
      background-color: $blue2;
      border: none;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
      }
    }
  }

  .cart-list {
    width: 100%;
    padding: 20px;

    @media screen and (max-width: 880px) {
      padding: 10px;
    }
    .item-container {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      background: $white2;
      width: 100%;
      padding: 10px;
      height: 170px;

      .img-container {
        width: 30%;
        min-width: 90px;
        height: 150px;
        display: flex;
        align-items: center;
        justify-content: end;

        img {
          width: 100%;
          height: 100%;
          padding: 0 20px 10px 0;
          object-fit: contain;

          @media screen and (max-width: 880px) {
            height: 120px;
            padding: 0 10px 10px 0;
          }

          @media screen and (max-width: 700px) {
            height: 90px;
            padding: 5px 0 5px 0;
            transform: scale(2);
          }
        }
      }

      .title-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        text-align: start;
        height: 150px;
        min-width: 40%;
        gap: 15px;

        @media screen and (max-width: 880px) {
          gap: 10px;
        }

        @media screen and (max-width: 700px) {
          gap: 5px;
          min-width: 35%;
        }

        @media screen and (max-width: 500px) {
          gap: 0;
          min-width: 25%;
        }

        h4 {
          font-size: 1.3rem;
          padding-left: 10px;

          @media screen and (max-width: 700px) {
            font-size: 0.9rem;
          }

          @media screen and (max-width: 550px) {
            font-size: 0.75rem;
            min-width: 150px;
          }
        }

        .weight {
          padding-left: 10px;
          font-size: 1rem;
          color: rgba(0, 0, 0, 0.644);
        }

        .qty-container {
          display: flex;
          align-items: center;
          z-index: 200;
          font-size: 1.2rem;

          @media screen and (max-width: 700px) {
            font-size: 0.9rem;
          }

          i {
            padding: 10px;
            cursor: pointer;
            transition: all 0.3s ease-in-out;

            &:hover {
              transform: scale(1.2);
              color: $blue1;
            }
          }
        }
      }

      .price-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: end;
        height: 150px;
        width: auto;

        i {
          font-size: 1.5rem;
          cursor: pointer;
          transition: all 0.3s ease-in-out;

          &:hover {
            color: $blue1;
          }
        }

        .unit-price {
          color: rgba(0, 0, 0, 0.644);
        }

        .price {
          width: 20%;
          min-width: 150px;
          font-size: 1.2rem;

          @media screen and (max-width: 880px) {
            min-width: 130px;
            font-size: 0.9rem;
          }

          @media screen and (max-width: 700px) {
            min-width: 85px;
            font-size: 0.8rem;
          }

          .total-item {
            font-size: 1.3rem;

            @media screen and (max-width: 700px) {
              font-size: 0.85rem;
            }
          }

          p {
            padding-top: 10px;
            font-weight: bold;
          }
        }
      }
    }
  }
  .shipping-options {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 15px;

    h3 {
      font-size: 1.7rem;
      cursor: pointer;
    }
    .option {
      display: flex;
      width: 100%;
      background: $white2;
      padding: 5px 10px;
      height: 50px;
      align-items: center;

      label {
        width: 100%;
        display: flex;
        justify-content: space-between;
        cursor: pointer;

        input {
          cursor: pointer;
        }
      }
    }
    .selected {
      background: $blue2;
    }
  }

  .promo-code {
    margin-top: 25px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    .promoCode-input-container {
      display: flex;
      align-items: center;
      
      input {
        background: none;
        border: 1px solid rgba(134, 134, 134, 0.479);
        padding: 7px 13px;
        width: 200px;
        height: 30px;
        
        &.input-promoCode-applied {
          border: 1px solid rgb(0, 184, 0);
        }
      }
      
      button {
        padding: 10px;
        height: 30px;
        margin: 10px;
        background-color: $blue2;
        border: none;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        cursor: pointer;
        transition: all 0.3s ease;
        
        &.promoCode-applied {
          background-color: rgb(0, 184, 0);
        }
      }
      }
      
    .success {
      color: rgb(0, 184, 0);
    }
    .error{
      color: $red1;
    }
  }

  .subtotal,
  .total,
  .shipping-fees,
  .discount {
    display: flex;
    justify-content: space-between;
    font-size: 1.2rem;
    margin-top: 30px;
    text-align: start;

    @media screen and (max-width: 700px) {
      font-size: 1rem;
    }

    div {
      text-align: end;
    }
  }

  .shipping-fees {
    h5 {
      max-width: 75%;
    }
  }

  .total {
    font-size: 1.5rem;
    @media screen and (max-width: 700px) {
      font-size: 1.3rem;
    }
  }

  .checkout {
    font-size: 1.2rem;
    margin-top: 15px;
  }

  .checkout {
    padding: 10px;
    height: 40px;
    width: 200px;
    margin: 10px 0 20px 10px;
    background-color: $blue2;
    border: none;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    }
  }
}
