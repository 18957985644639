.navbar {
  display: flex;
  align-items: center;
  transition: all 0.5s ease-in-out;
  width: 100%;
  height: 70px;
  background-color: rgba(255, 255, 255, 0);
  color: $black1;
  position: fixed;
  top: 0;
  z-index: 150;
  left: 0;
  background: rgba(255, 255, 255, 0);
  // box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);

  .fixed-cart {
    display: none;
    position: fixed;
    z-index: 300;
    top: 25px;
    right: 25px;
    background: $blue1;
    padding: 10px;
    border-radius: 45px;
    color: $white1;
    font-size: 1.3rem;
    transition: all ease-in-out 0.3s;
    cursor: pointer;
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    }

    .cart-badge {
      position: absolute;
      top: -8px;
      right: -8px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: red;
      color: white;
      font-size: 12px;
      font-weight: bold;
    }
  }

  .fixed-cart.hide {
    display: block;
  }

  .main-box {
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .menu {
      display: flex;
      color: $white1;
      align-items: center;
      cursor: pointer;
      transition: all ease-in-out 0.3s;

      &:hover {
        color: $blue1;
      }

      i {
        margin-right: 10px;
        font-size: 1.3rem;

        @media screen and (max-width: 600px) {
          font-size: 1.1rem;
        }

        @media screen and (max-width: 400px) {
          font-size: 0.9rem;
        }
      }
      p {
        font-size: 1.3rem;

        @media screen and (max-width: 600px) {
          font-size: 1.1rem;
        }

        @media screen and (max-width: 400px) {
          font-size: 0.9rem;
        }
      }
    }

    .title {
      cursor: pointer;
      font-size: 1.6rem;
      font-family: $font1;
      color: $white1;
      text-shadow: 2px 2px $red1;
      transition: all 0.3s ease-in-out;

      &:hover {
        transform: scale(1.04);
      }

      @media screen and (max-width: 1000px) {
        font-size: 1.4rem;
      }

      @media screen and (max-width: 600px) {
        font-size: 1.2rem;
      }

      @media screen and (max-width: 440px) {
        font-size: 1rem;
      }

      @media screen and (max-width: 400px) {
        font-size: 0.8rem;
      }
    }

    .icons-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 30px;
      z-index: 2;
      list-style: none;

      .icon {
        position: relative;
        color: $white1;
        font-size: 1.3rem;
        transition: all ease-in-out 0.3s;
        cursor: pointer;

        &:hover {
          color: $blue1;
        }

        @media screen and (max-width: 600px) {
          display: none;
        }

        .cart-badge {
          position: absolute;
          top: -8px;
          right: -8px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: red;
          color: white;
          font-size: 12px;
          font-weight: bold;
        }
      }
    }
  }
  .main-box.hide {
    visibility: hidden;
    pointer-events: none;
    height: 0;
    overflow: hidden;
    z-index: -10000;
  }
}
