.home-container {
  background: url(../../img/img-5.jpg) center/cover;
  height: 100vh;

  .header-container {
    height: 100vh;

    .logo {
      padding-top: 12%;
      width: 180px;
      @media screen and (max-width: 680px) {
        width: 120px;
        padding-top: 50%;
      }
    }

    h2 {
      font-family: $font1;
      color: $white1;
      text-shadow: 2px 2px $red1;
      font-size: 2.2rem;
      padding-top: 20px;
      letter-spacing: 1px;

      @media screen and (max-width: 680px) {
        font-size: 1.7rem;
      }
    }
    button {
      margin-top: 40px;
      height: 40px;
      width: 200px;
      color: $white1;
      font-family: $font2;
      font-weight: 600;
      font-size: 1rem;
      letter-spacing: 3px;
      border: 2px solid $white1;
      background: rgba(128, 128, 128, 0.395);
      cursor: pointer;
      transition: all 0.5s ease-in-out;

      &:hover {
        transform: scale(1.07);
      }
    }
  }
  .section1 {
    display: grid;
    grid-template-columns: 60% 40%;
    background: $white2;
    min-height: 350px;

    @media screen and (max-width: 680px) {
      grid-template-columns: 1fr;
    }

    .intro-text {
      margin: 15px;
      padding: 30px 15px;
      border: 3px dotted $blue1;
      color: $black1;
      align-items: center;
      line-height: 25px;
      @media screen and (max-width: 680px) {
        line-height: normal;
      }

      h2 {
        color: $black2;
        font-family: $font1;
        font-size: 2rem;
        font-weight: 500;
      }
      h3 {
        margin-top: -6px;
        font-family: $font2;
        color: $blue1;
      }
      p {
        margin-top: 30px;
      }
    }
    .intro-pic {
      background: url(../../img/vincent-2.jpg) center/cover;
      min-height: 300px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: $white1;

      h3 {
        text-transform: uppercase;
        font-size: 1.7rem;
        font-weight: bold;
      }

      ul {
        list-style: none;
        font-size: 1.2rem;
        display: flex;
        justify-content: space-evenly;
        align-items: flex-start;
        margin-top: 10px;

        li {
          color: $white1;
          transition: all 0.3s ease-in-out;
          font-size: 1.8rem;
          margin: 15px;

          &:hover {
            transform: scale(1.2);
            color: $blue1;
          }
        }
      }
    }
  }

  .section2 {
    display: grid;
    grid-template-columns: 40% 60%;
    background: $black3;
    min-height: 350px;

    @media screen and (max-width: 680px) {
      grid-template-columns: 1fr;
      .intro-text {
        order: 1;
      }

      .intro-pic {
        order: 2;
      }
    }

    .intro-text {
      margin: 15px;
      padding: 30px 15px;
      border: 3px dotted $red1;
      color: $white2;

      h2 {
        color: $white1;
        font-family: $font1;
        font-size: 2rem;
        font-weight: 500;
      }
      h3 {
        margin-top: -6px;
        font-family: $font2;
        color: $red1;
      }

      p {
        margin-top: 30px;
        a {
          color: $red2;
        }
      }
    }
    .intro-pic {
      background: url(../../img/red-bg.jpg) center/cover;
      min-height: 300px;
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        margin-top: 40px;
        height: 40px;
        width: 200px;
        color: $white1;
        font-family: $font2;
        font-weight: 600;
        font-size: 1rem;
        letter-spacing: 3px;
        border: 2px solid $white1;
        background: rgba(128, 128, 128, 0.395);
        cursor: pointer;
        transition: all 0.5s ease-in-out;

        &:hover {
          transform: scale(1.07);
        }
      }
    }
  }
  .section3 {
    display: grid;
    grid-template-columns: 60% 40%;
    background: $white2;
    min-height: 350px;

    @media screen and (max-width: 680px) {
      grid-template-columns: 1fr;
    }

    .intro-text {
      margin: 15px;
      padding: 30px 15px;
      border: 3px dotted $blue1;
      color: $black1;
      align-items: center;

      h2 {
        color: $black2;
        font-family: $font1;
        font-size: 2rem;
        font-weight: 500;
      }
      h3 {
        margin-top: -6px;
        font-family: $font2;
        color: $blue1;
      }
      p {
        margin-top: 30px;
        text-align: start;
      }
    }
    .intro-pic {
      background: url(../../img/section-pro.png) center/cover;
      min-height: 300px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: $white1;
      .contact-us {
        background: rgba(0, 0, 0, 0.5);
        padding: 10px;
        h3 {
          font-size: 2rem;
          margin-bottom: 10px;
        }

        p {
          font-weight: bold;
          transition: all 0.3s ease-in-out;
          &:hover {
            transform: translateX(5px);
          }
        }
      }
    }
  }
  .section4 {
    display: flex;
    justify-content: center;
    padding: 20px;
    background: $white1;

    @media screen and (max-width: 980px) {
      flex-direction: column;
      margin-bottom: 80px;
    }
    @media screen and (max-width: 680px) {
      flex-direction: column;
      margin-bottom: 110px;
    }
    .info-card {
      display: flex;
      width: 25%;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      gap: 10px;
      margin-top: 20px;
      min-height: 220px;

      @media screen and (max-width: 980px) {
        gap: 20px;
        width: 100%;
      }

      img {
        width: 70px;
      }
      h5 {
        font-size: 1.4rem;
        text-transform: uppercase;
      }
      p {
        width: 80%;
      }
      @media screen and (max-width: 880px) {
        &:nth-child(4) {
          margin-bottom: 60px;
        }
      }
    }
  }
}
