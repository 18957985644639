.shipping-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 700px;

  h3 {
    margin-top: 30px;
    font-size: 2.5rem;
    font-family: $font1;
  }

  .form-container {
    width: 60%;
    padding: 20px;
    box-sizing: border-box;

    form {
      height: 90%;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 8px;

      h3 {
        margin: 10px 0 20px 0;
      }

      input {
        width: 90%;
        font-family: $font2;
        color: $black1;
        font-size: 1rem;
        font-weight: 500;
        padding: 10px 0;
        margin: 10px;
        background: none;
        outline: none;
        border: none;
        border-bottom: 1px solid $black1;
        resize: none;
        &::placeholder {
          text-transform: uppercase;
        }
      }
    }

    .btn-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 90%;
      margin: 0 5% 0 5%;

      .back-btn {
        padding: 10px;
        width: 10%;
        min-width: 50px;
        height: 40px;
        margin: 10px 0 10px 10px;
        background-color: $blue2;
        border: none;
        font-size: 16px;
        cursor: pointer;
        transition: all 0.3s ease;

        &:hover {
          transform: translateY(-2px);
          box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
        }
      }
      @media screen and (max-width: 880px) {
        margin: 30px 5% 0 5%;
      }
    }
    .checkbox-container {
      width: 95%;
      margin-bottom: 45px;
      .cgv,
      .newsletter {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        text-align: start;
        width: 100%;
        padding: 10px 0;
        p {
          width: 90%;
          font-weight: 200;
        }
        input {
          width: 30px;
        }
      }
    }
  }

  .recap-container {
    width: 45%;
    height: auto;
    box-sizing: border-box;
    overflow-y: scroll;
    z-index: 1;
    background: $white2;

    .recap-title-container {
      position: sticky;
      top: 0;
      z-index: 5;
      width: 100%;
      min-height: 50px;
      background: $white2;
      display: flex;
      justify-content: center;

      h3 {
        padding-bottom: 25px;
      }
    }

    .cart-list {
      padding: 0 10px 0 10px;
      margin: 0;
      justify-content: flex-start;

      .item-container {
        height: 80px;
        padding: 10px 10px 10px 5px;
        margin: 5px;
        background-color: $white1;
        .title-container {
          height: 80px;
          gap: 0;

          h4 {
            font-size: 0.9rem;
            min-width: 150px;
          }

          .weight {
            font-size: 0.7rem;
          }
        }
        .price-container {
          gap: 0;
          height: 50px;
          .unit-price {
            font-size: 0.8rem;
          }

          .total-item {
            font-size: 0.9rem;
          }
        }
      }
      @media screen and (max-width: 880px) {
        display: none;
      }
    }
  }

  .total-container {
    position: sticky;
    bottom: 0;
    z-index: 1;
    height: auto;
    width: 100%;
    background: $white2;
    padding: 10px 10px 25px 20px;

    .total,
    .shipping-fees,
    .subtotal,
    .discount {
      margin-top: 5px;
    }

    .shipping-fees,
    .subtotal,
    .discount {
      font-weight: 200;

      h4,
      h5 {
        font-weight: 200;
      }
    }
  }
}

@media (max-width: 880px) {
  .shipping-container {
    flex-direction: column-reverse;

    .form-container,
    .recap-container {
      width: 100%;
      overflow-y: auto;

      h3 {
        font-size: 1.9rem;
      }
    }

    .recap-container {
      padding: 0 20px;
    }
  }
}
