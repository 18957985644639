.toggleMenu-container {
  z-index: 10000;
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  top: 0;
  right: 0;
  background: rgba(202, 202, 202, 0.733);
  transition: all ease-in-out 0.5s;
  .left-side {
    position: fixed;
    left: 0;
    bottom: 0;
    height: 100vh;
    width: 20%;
    min-width: 200px;
    background: rgba(255, 255, 255, 0.932);
    z-index: 100000;

    .menu-toggle-button {
      display: flex;
      justify-content: space-between;
      font-size: 1.6rem;
      align-items: center;
      height: 65px;
      border-bottom: 2px solid $black2;
      cursor: pointer;
      p {
        margin: 10px;
        font-weight: bold;
      }

      i {
        margin: 10px;
      }
    }

    .menu-list {
      input[type="text"] {
        background: none;
        outline: none;
        border: none;
        margin: 10px;
        width: 60%;
      }
      input[type="text"]::placeholder {
        color: $black1;
      }
      i {
        color: $black1;
        font-size: 1.3rem;
      }
      li {
        height: 50px;
        display: flex;
        justify-content: flex-start;
        text-align: center;
        list-style: none;
        width: 100%;
        border-bottom: 1px solid $black1;
        align-items: center;
        cursor: pointer;
        transition: all 0.3s ease;
        letter-spacing: 1px;

        &:hover {
          letter-spacing: 2px;
          background: $white1;
        }

        p {
          font-size: 1.5rem;
          font-family: $font2;
          text-decoration: none;
          color: $black1;
          margin: 10px;
        }
      }
    }

    .social-link {
      ul {
        list-style: none;
        font-size: 1.2rem;
        display: flex;
        justify-content: space-evenly;
        align-items: flex-start;
        margin-top: 50px;

        li {
          margin-top: 10px;
          color: $black1;
          transition: all 0.3s ease-in-out;

          &:hover {
            transform: scale(1.2);
            color: $blue1;
          }
        }
      }
    }

    .contact-info {
      margin: 45px 10px 30px 10px;

      h2 {
        font-family: $font1;
      }
      p {
        transition: all 0.3s ease-in-out;
        margin: 10px;
        &:hover {
          transform: translateX(5px);
        }
        @media screen and (max-width: 880px) {
          display: none;
        }
      }

      .address {
        &:hover {
          transform: none;
        }
        @media screen and (max-width: 880px) {
          display: none;
        }
      }
    }

    .logo-menu {
      width: 80px;
      cursor: pointer;
    }
  }
  .right-side {
    width: 80%;
    height: 100%;
  }
}
