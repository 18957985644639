.footer-container {
  height: 200px;
  width: 100%;
  background: $white2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);

  @media screen and (max-width: 880px) {
    flex-direction: column;
    flex-direction: column-reverse;
  }

  .left-side {
    display: flex;
    align-items: center;
    background-color: $white1;
    width: 50%;
    height: 100%;
    z-index: 1;

    @media screen and (max-width: 880px) {
      background-color: $white1;
      width: 100%;
      justify-content: center;
      gap: 10px;
    }

    .logo {
      width: 120px;
      transition: all 0.3s ease-in-out;
      margin-right: 50px;
      cursor: pointer;
      margin-left: 20px;

      @media screen and (max-width: 880px) {
        width: 90px;
        margin-right: 30px;
      }

      @media screen and (max-width: 600px) {
        width: 70px;
        margin-right: 15px;
      }
    }
    .contact {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      line-height: 20px;
      font-size: 1.2rem;

      .title-contact {
        font-family: $font1;
        font-size: 1.8rem;
        font-weight: bold;
        margin-bottom: 10px;
        margin-top: 20px;
        display: flex;
        align-items: flex-start;

        @media screen and (max-width: 980px) {
          font-size: 1.4rem;
        }

        @media screen and (max-width: 550px) {
          font-size: 1.1rem;
        }
      }

      .address-contact {
        font-style: italic;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-wrap: inherit;
        line-height: 30px;
        @media screen and (max-width: 980px) {
          line-height: 20px;
        }
      }

      .mail-phone-contact {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        line-height: 30px;
        p {
          transition: all 0.3s ease-in-out;
          &:hover {
            transform: translateX(5px);
          }
        }

        @media screen and (max-width: 980px) {
          line-height: 20px;
        }
      }
      .legal-mention {
        font-size: 0.7rem;
        padding-bottom: 5px;
        text-align: start;
      }

      @media screen and (max-width: 980px) {
        font-size: 1rem;
      }

      @media screen and (max-width: 600px) {
        font-size: 0.8rem;
      }
    }
  }

  .right-side {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 50%;
    height: 100%;
    position: relative;
    background-color: $white2;

    @media screen and (max-width: 880px) {
      width: 100%;
      box-shadow: 0 -10px 15px rgba(0, 0, 0, 0.123);
      justify-content: space-around;
    }

    .nav {
      display: flex;
      flex-direction: column;
      margin-right: 70px;

      @media screen and (max-width: 880px) {
        margin-right: 0;
      }

      h5 {
        font-size: 1rem;
        margin-top: 0px;
        letter-spacing: 1px;
        @media screen and (max-width: 880px) {
          font-size: 0.8em;
          margin-top: -10px;
        }
      }

      ul {
        list-style: none;
        font-size: 1.2rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        @media screen and (max-width: 880px) {
          margin: 10px;
          font-size: 0.8rem;
        }

        li {
          margin-top: 8px;
          letter-spacing: 2px;
          cursor: pointer;
          transition: all 0.3s ease-in-out;

          &:hover {
            color: $blue1;
          }
        }
      }
    }
    h5 {
      text-align: start;
      font-weight: bold;
      margin: 10px 10px 10px 0;
      letter-spacing: 1px;
      font-size: 1rem;
      @media screen and (max-width: 880px) {
        font-size: 0.8em;
      }
    }
    .social-link {
      ul {
        list-style: none;
        font-size: 1.2rem;
        display: flex;
        align-items: flex-start;

        li {
          margin: 5px 20px 5px 10px;
          color: $black1;
          transition: all 0.3s ease-in-out;

          &:hover {
            transform: scale(1.3);
            color: $blue1;
          }
        }
      }
      @media screen and (max-width: 880px) {
        margin-top: 20px;
      }
    }
    .newsletter {
      input {
        background: none;
        border: 1px solid rgba(134, 134, 134, 0.479);
        padding: 7px 13px;
        width: 200px;
        @media screen and (max-width: 880px) {
          width: 150px;
        }
      }
      i {
        margin-left: 8px;
        text-align: center;
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        &:hover {
          transform: scale(1.2);
          color: $blue1;
        }
      }
      .fa-check {
        color: rgb(0, 184, 0);
        font-size: 1.2rem;
        &:hover {
          transform: none;
          color: rgb(0, 184, 0);
        }
      }
      @media screen and (max-width: 880px) {
        margin-bottom: 20px;
      }
    }
  }
}
