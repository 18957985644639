// FixedCart.scss
.fixed-cart {
  display: none;
  position: fixed;
  z-index: 3000;
  top: 25px;
  right: 25px;
  background: $blue1;
  padding: 10px;
  border-radius: 45px;
  color: $white1;
  font-size: 1.3rem;
  transition: all ease-in-out 0.3s;
  cursor: pointer;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  }

  .cart-badge {
    position: absolute;
    top: -8px;
    right: -8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: red;
    color: white;
    font-size: 12px;
    font-weight: bold;
  }
}

.fixed-cart.show {
  display: block;
}

.fixed-cart.hide {
  display: none;
}
