.item-details-page {
  padding-top: 130px;

  .bg-img {
    background: url(../../img/img-5.jpg) center/cover;
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .item-details-container {
    display: grid;
    margin: 50px 10% 150px 10%;
    grid-template-columns: 50% 50%;
    background: $white1;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.3);
    width: 80%;

    @media screen and (max-width: 850px) {
      grid-template-columns: 1fr;
    }

    .image-container {
      width: 100%;
      padding: 20px;
      background-color: $white2;

      img {
        filter: brightness(110%);
        // width: 100%;
        max-height: 100%;
      }
    }

    .details-container {
      height: 80%;
      width: 100%;
      padding: 25px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;

      .details {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        margin-top: 20px;
        gap: 20px;

        .price {
          font-size: 1.6rem;
          font-weight: bold;
        }

        .description {
          text-align: left;
          letter-spacing: 2px;
          line-height: 22px;
        }

        .nutri-values-container {
          font-size: 0.8rem;
          text-align: left;
          letter-spacing: 1px;
          line-height: 20px;
          transition: all 0.3s ease;

          .toggle-btn {
            font-size: 1rem;
            text-decoration: underline $black2 solid 1px;
            cursor: pointer;

            i {
              text-decoration: underline $black2 solid 1px;
            }
          }
          .toggle-text {
            max-height: 0;
            overflow: hidden;
          }

          .toggle-text.show {
            max-height: 1000px;
            transition: max-height 0.8s ease-in-out;
          }

          .toggle-text.hide {
            max-height: 0;
            transition: max-height 1s ease-in-out;
          }
        }

        h3 {
          font-family: $font1;
          font-size: 2.5rem;
          margin-bottom: 30px;
          text-align: left;
        }
        p {
          margin-bottom: 20px;
        }
      }

      .qty-btn-container {
        display: flex;
        margin: 0 0 30px -8px;
        .qty-btn {
          display: flex;
          align-items: normal;
          margin: 5px;
          width: 25px;
          height: 25px;
          transition: all 0.3s ease;

          &:hover {
            transform: scale(1.2);
          }
          i {
            margin: 0 5px;
            cursor: pointer;
            transform: translateY(20%);
          }
        }
        .quantity {
          font-size: 18px;
          font-weight: 700;
          margin: 0 5px;
          color: $black1;
          transform: translateY(20%);
        }
      }
    }
    .cart-btn {
      padding: 10px;
      width: 100%;
      min-width: 200px;
      height: 30px;
      background-color: $blue2;
      border: none;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        transform: translateY(-3px);
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
      }

      i {
        margin-left: 10px;
      }
    }
    .unavailable {
      background-color: $red2;
      cursor: default;

      &:hover {
        transform: none;
        box-shadow: none;
      }
    }

    .cart-btn.added {
      background-color: rgb(0, 184, 0);
    }
  }
  .related-container {
    background: $white1;

    @media screen and (max-width: 880px) {
      padding: 40px 0 130px 0;
    }
    .related-title {
      font-family: $font1;
      font-size: 2.5rem;
      padding: 20px 0;
      background: $white2;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
    }
    .related-items {
      display: flex;
      flex-wrap: wrap;
    }
  }
}
