.cart-container {
  z-index: 10000;
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  top: 0;
  right: 0;
  background: rgba(202, 202, 202, 0.733);
  transition: all ease-in-out 0.5s;

  .left-side {
    width: 60%;
    height: 100%;
  }

  &.open {
    right: 0px;
  }

  &.close {
    display: none;
  }

  .separator-line {
    margin: 0 10px;
  }

  .cart-menu {
    position: fixed;
    height: 100vh;
    right: 0;
    overflow: auto;
    opacity: 1;
    width: 40%;
    min-width: 270px;
    margin: 10px;
    background-color: rgb(255, 255, 255);
    transition: all ease-in-out 0.5s;

    .cart-header {
      position: sticky;
      z-index: 5;
      top: 0;
      padding: 10px;
      height: 80px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: rgb(255, 255, 255);

      h3 {
        font-size: 2rem;
        margin: 0;
        @media screen and (max-width: 500px) {
          font-size: 1.5rem;
        }
      }

      .fa-arrow-right-from-bracket {
        cursor: pointer;
        font-size: 2rem;
        @media screen and (max-width: 500px) {
          font-size: 1.5rem;
        }
      }
    }

    .empty-cart {
      padding: 50px 0 50px 0;
      font-size: 1.5rem;
      font-weight: 200;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      button {
        padding: 10px;
        height: 40px;
        width: 200px;
        margin: 20px 0 10px 10px;
        background-color: $blue2;
        border: none;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.3s ease;

        &:hover {
          transform: translateY(-2px);
          box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
        }
      }
    }

    .cart-list {
      padding: 10px;
      overflow-y: hidden;

      .cart-list-container {
        display: flex;
        margin-bottom: 10px;
        padding: 10px;
        background: $white2;

        .img-container {
          width: 100px;
          padding: 5px;
          margin-right: 10px;

          img {
            width: 100%;
            height: auto;
            transform: scale(1.3);
            filter: brightness(130%);
          }
        }

        .details-container {
          flex-grow: 1;
          display: flex;
          flex-direction: column;

          .title-container {
            display: flex;
            justify-content: space-between;

            margin-bottom: 5px;

            .title {
              font-size: 16px;
              margin: 0;
              text-align: left;
            }

            .fa-xmark {
              cursor: pointer;
            }
          }

          .qty-container {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .qty-btn-container {
              display: flex;
              .qty-btn {
                display: flex;
                margin: 5px;
                width: 25px;
                height: 25px;
                background-color: $white1;
                border-radius: 45px;
                transition: all 0.3s ease;

                &:hover {
                  transform: translateY(-3px);
                  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
                }
                i {
                  margin: 0 5px;
                  cursor: pointer;
                  transform: translateY(20%);
                }
              }
            }
            .price {
              font-size: 16px;
            }

            .quantity {
              font-size: 18px;
              font-weight: 700;
              margin: 0 5px;
              color: $black1;
              transform: translateY(20%);
            }
          }
        }
      }
    }
    .cart-footer {
      position: sticky;
      bottom: 0;
      z-index: 1;
      height: 120px;
      background-color: $white1;

      .subtotal {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;

        h4 {
          font-size: 1.3rem;
          margin: 10px;
          @media screen and (max-width: 500px) {
            font-size: 1rem;
          }
        }

        .total-price {
          font-size: 1.3rem;
          margin: 10px;
          @media screen and (max-width: 500px) {
            font-size: 1rem;
          }
        }
      }
      .free-shipping {
        margin-left: 10px;
        font-size: 1rem;
        text-align: center;
      }
      .btn-container {
        display: flex;

        .exit-cart {
          padding: 10px;
          width: 20%;
          margin: 10px 0 10px 10px;
          background-color: $blue2;
          border: none;
          font-size: 16px;
          cursor: pointer;
          transition: all 0.3s ease;

          @media screen and (max-width: 500px) {
            font-size: 12px;
          }

          &:hover {
            transform: translateY(-2px);
            box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
          }
        }
      }

      .checkout {
        padding: 10px;
        width: 95%;
        margin: 10px;
        background-color: $blue2;
        border: none;
        font-size: 1.2rem;
        letter-spacing: 1px;
        cursor: pointer;
        transition: all 0.3s ease;

        @media screen and (max-width: 500px) {
          font-size: 15px;
        }

        &:hover {
          transform: translateY(-2px);
          box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
        }
      }
    }
  }
}
