.failed-page {
  background: url(../../img/img-5.jpg) center/cover;
  height: 100vh;
  padding-top: 120px;

  .failed-container {
    position: relative;
    min-height: 300px;
    background: $white1;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    z-index: 100;
    width: 80%;
    margin: 0 10% 50px 10%;
    padding: 10px;
    display: flex;
    font-weight: 700;
    flex-direction: column;
    align-items: center;

    h2 {
      margin: 20px;
      font-family: $font1;
      font-size: 2.3rem;
    }

    button {
      height: 35px;
      width: 100px;
      min-width: 180px;
      color: $black1;
      font-family: $font2;
      font-weight: 600;
      font-size: 1rem;
      text-transform: uppercase;
      letter-spacing: 3px;
      background-color: $blue2;
      border: none;
      margin: 35px 0;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:hover {
        transform: translateY(-3px);
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
      }
    }

    .order-confirmation-container {
      padding: 20px;
      display: flex;
      align-items: center;

      .message-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        h3 {
          font-size: 1.5rem;
        }
      }

      i {
        font-size: 3rem;
        color: $red1;
        border: solid 5px $red1;
        border-radius: 45px;
        padding: 15px;
        margin: 10px 30px 10px 10px;
      }
    }
  }
}
