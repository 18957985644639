.not-found-page {
  background: url(../../img/img-5.jpg) center/cover;
  height: 100vh;
  width: auto;

  .not-found-container {
    position: absolute;
    left: 15%;
    top: 40%;
    text-align: start;

    @media screen and (max-width: 900px) {
      left: 5%;
    }

    h2 {
      color: $white1;
      font-family: $font2;
      text-transform: uppercase;
      letter-spacing: 3px;
      font-size: 2.5rem;
    }

    .back-home {
      height: auto;
      min-height: 35px;
      width: 100%;
      margin: 30px 5px;
      color: $white1;
      font-family: $font2;
      font-weight: 800;
      font-size: 1rem;
      text-transform: uppercase;
      letter-spacing: 3px;
      border: 2px solid $white1;
      background: rgba(255, 255, 255, 0);
      cursor: pointer;
      transition: all 0.7s ease-in-out;

      &:hover {
        color: $black1;
        background: $white1;
      }
    }
  }
}
